import React from 'react';

const MusicDAO = () => {
	return (
		<React.Fragment>
			<div>MusicDAO Page</div>
		</React.Fragment>
	);
};

export default MusicDAO;